import { createInjectionState } from '@vueuse/core'
import { tron, tronErrors } from '~/utils/tron-v2'

export const [useMultiSignProvider, useMultiSign] = createInjectionState(() => {
  const info = reactive({ isOwnerPermission: false, permissionId: 0 })

  const addressMuktiSign = ref('')

  const { account: accountCurrent } = tronComp.useAccountCurrent()
  const { account: accountMultiSign } = tronComp.useAccount(
    addressMuktiSign,
    toRef(accountCurrent, 'chainId'),
  )

  /**
   * 验证多签是否有权限
   */
  const onValidatePower = (isMultiSign: boolean | Ref<boolean>, id: number) => {
    if (!toValue(isMultiSign))
      return

    info.isOwnerPermission = false
    info.permissionId = 0
    const {
      owner_permission: ownerPermission,
      active_permission: activePermission,
    } = accountMultiSign

    if (!ownerPermission || !activePermission)
      info.isOwnerPermission = false

    ownerPermission?.keys.forEach((i:any) => {
      if (i.address === tron.tronWeb?.address.toHex(accountCurrent.address))
        info.isOwnerPermission = true
    })

    if (!info.isOwnerPermission) {
      activePermission?.forEach((i:any) => {
        if (
          i.keys.some(
              (obj: { address: string }) =>
              obj.address
              === tron.tronWeb?.address.toHex(accountCurrent.address),
          )
        ) {
          if (tron.getPermission(i.operations, id))
            info.permissionId = i.id
        }
      })

      if (!info.permissionId)
        throw tronErrors.MULTISIGN_ERROR()
    }
  }

  const setAddress = (address: string) => {
    if (!tron.isAddress(address))
      return
    addressMuktiSign.value = address
  }

  return {
    info,
    onValidatePower,
    setAddress,
  }
})
