<script setup lang="ts">
import { useAppBreakpoints } from '@peng_kai/theme/vue/index'
import * as Sentry from '@sentry/vue'
import { useBuyProvider } from './hooks/useBuy'
import { useC2cBuyMachine } from './machine/useC2cBuyMachine'
import BuyPopupGather from './BuyPopupGather.vue'
import { useResource } from '~/composables/tron'
import { TronRes, tronErrors } from '~/utils/tron-v2'
import CustomInputNumber from '~/components/custom-input-number'
import RemtaDuration from '~/components/remta-duration'
import { EPaymentMethod } from '~/components/pay-type/src/PayType.vue'
import { imtoken } from '~/utils/imtoken'
import CustomCalendar from '~/components/custom-calendar/index'
import useHideFormHelp from '@/composables/useHideFormHelp'
import CheckBox from '~/components/check-box'
import BigNumber, { default as bignumber } from 'bignumber.js';


const { machine, states } = useC2cBuyMachine()
const { account, isConnected } = tronComp.useAccountCurrent()
const { config } = useAppState()
const isAutoCancel = ref(false)

const {
  shortcut,
  changeEnergyUnit,
  unitPriceRange,
  priceRange,
  shortcutTime,
  formData,
  buyForm,
  chooseRentDuration,
  discount: discountInfo,
  isUnaddressDom,
  payType,
  buttonGroup,
  paymentAddress,
  paymentMethod,
  isPenNumber,
} = useBuyProvider()

const { target, checking } = useHideFormHelp(buyForm.$form)
const bp = useAppBreakpoints()

const { resourceType: ResourceType } = useResource()

let receiveAddress = ''
async function onSubmit() {
  await tronkickOrderValidate()
  const address = receiveAddress || formData.receiveAddress
  formData.waitSecond = !!isAutoCancel.value ?  formData.waitSecond : 0
  machine.send({
    type: 'BUY',
    formData: {
      ...formData,
      resourceValue: Number(formData.resourceValue),
      receiveAddress: address,
    },
    paymentAddress: paymentAddress.value,
    discountInfo,
    toAddress: account.address,
  })
  receiveAddress = ''
}

async function onValidate() {
  if (imtoken.isImToken() && !(window as any)?.tronWeb) {
    imtoken.showAccountSwitch()
    return
  }

  const formState = (await buyForm.$form.value?.validate().catch(() => {
    checking.setTrue()
  })) as typeof buyForm.formState

  receiveAddress = formData.receiveAddress

  if (formState) {
    payType.value = EPaymentMethod.DAPP
    onSubmit()
  }
}

function tronkickOrderValidate() {
  return new Promise((resolve, reject) => {
    if (!paymentAddress.value) {
      aMessage.error($t('-i3J6LI6-BB6Q4IYBslVe'))
      reject('')
    }
    const amount = bignumber(discountInfo.amount);

    if (amount.isNaN() || amount.isLessThanOrEqualTo(0)) {
      aMessage.error($t('mONxWJ4VZlBX33YlMI7yY'))
      reject('')
    }

    resolve(true)
  })
}

const arr = [
  { value: TronRes.ENERGY, name: $t('Mqe_hlD_yGPsAMB9oaO1D') },
  { value: TronRes.BANDWIDTH, name: $t('xo7kGD9R2fV50wOIihlhL') },
]

</script>

<template>
  <div
    class="buy-container gradient-border-box"
    :class="
      isConnected
        ? 'at-tabletl:max-h-600px at-laptop:max-h-600px '
        : 'laptopl:max-h-610px'
    "
  >
    <div>
      <div class="flex-between lt-mobile:grid lt-mobile:text-center lt-mobile:justify-stretch">
        <div
          class="header text-[26px] text-[#e2e8f2] font-semibold lt-mobile:text-[26px] lt-mobile:mb-[10px]"
        >
          {{ $t("zmbvS1ttTui9nZPJrO8tv")
          }}{{ $t(ResourceType[formData.resourceType].label) }}
        </div>
        <CheckBox v-model:type="formData.resourceType" :mode="arr" />
      </div>

      <AForm
        class="antd-cover__bubble-help-form bubble-help-top my-18px! lt-mobile:text-16px lt-mobile:text-primary lt-mobile:mb-5px!"
        v-bind="buyForm.formProps"
        layout="vertical"
        :size="$bp.ltTabletl ? 'small' : undefined"
      >
        <!-- 输入框部分 -->
        <div>
          <AFormItem
            class="ant-cover__basic-form-item [--bubble-offset:60px]"
            v-bind="buyForm.formItemProps.receiveAddress"
          >
            <template #label>
              <div class="flex justify-between w-full">
                <span>{{ $t("SMmd46cyjpYFJ9oPU4tuB") }}</span>
                <!-- 显示连接钱包按钮 -->
                <div
                  v-if="isUnaddressDom && !isConnected"
                  class="flex justify-center items-center cursor-pointer text-#148AF5 hover:text-text"
                  data-test-id="quy9"
                  @click="globalModal.ConnectWallet?.open()"
                >
                  <i class="i-local:wallet-s3 text-1.25em mr-1" />
                  <span>{{ $t("tLprg_biKgG1SBuqQghN-") }}</span>
                </div>
              </div>
            </template>
            <AInput
              v-model:value="formData.receiveAddress"
              v-focus-right-directive
              autocomplete="off"
              :placeholder="$t('ppdFBmK0Ukkj8xibXd05')"
              allowClear
              data-test-id="KkKR"
            >
              <template #prefix>
                <i class="i-local:tron-wallet-s2 text-1.25em" />
              </template>
              <template #clearIcon>
                <i
                  class="i-ant-design:close-circle-outlined text-primary text-1.25em"
                />
              </template>
            </AInput>
          </AFormItem>

          <!-- 能量数量 -->
          <AFormItem
            class="ant-cover__basic-form-item [--bubble-offset:100px]"
            v-bind="buyForm.formItemProps.resourceValue"
          >
            <template #label>
              <div class="flex justify-between items-center">
                <div
                  class="text-left whitespace-nowrap laptopl:text-lg lt-tabletl:text-md flex justify-center items-center"
                >
                  <span
                    v-if="
                      formData.resourceType === TronRes.ENERGY && isPenNumber
                    "
                  >{{ $t("exnibzgWBvKuAc4PmIno") }}</span>
                  <span
                    v-if="
                      formData.resourceType === TronRes.ENERGY && !isPenNumber
                    "
                  >{{ $t("hRcSrbvS4FCufVdi7drVr") }}</span>
                  <span v-if="formData.resourceType !== TronRes.ENERGY">{{
                    $t("eN9O0GbxHcsHlih4V9sN")
                  }}</span>
                  <ATooltip>
                    <template
                      v-if="formData.resourceType === TronRes.ENERGY"
                      #title
                    >
                      {{
                        isPenNumber
                          ? $t("56KKf1NCm92oPaMm09fhx")
                          : $t("dzGehsFax9sdGoUEy5jx")
                      }}
                    </template>
                    <template v-else #title>
                      {{ $t("nrOHwx2nBv5apygFwfRqi") }}
                    </template>
                    <i
                      class="i-ant-design:question-circle-outlined ml1 text-1.25em color-#8098bf"
                    />
                  </ATooltip>
                </div>

                <TransferCountHint
                  v-if="
                    formData.resourceValue
                      && formData.resourceType === TronRes.ENERGY
                  "
                  :rentResValue="formData.resourceValue"
                  :rentDur="formData.rentDuration"
                  :durUnit="formData.rentTimeUnit"
                  :haveTokenEnergyUsed="config?.estimate.have_token_energy_used"
                  :notHaveTokenEnergyUsed="
                    config?.estimate.not_have_token_energy_used
                  "
                />
              </div>
            </template>
            <RemtaDuration
              v-model="formData.resourceValue"
              :shortcut="shortcut"
              :resourceType="formData.resourceType"
              :min="unitPriceRange.c2cMinValue"
              :max="unitPriceRange.c2cMaxValue"
              mode="c2c"
              @changeEnergyUnit="changeEnergyUnit"
              @onShortcutChange="
                buyForm.$form.value
                  ?.validate('resourceValue', { triggerName: 'change' })
                  .catch(() => {})
              "
            />
          </AFormItem>

          <div class="flex-between gap-[20px]">
            <!-- 租用单价 -->
            <AFormItem
              class="ant-cover__basic-form-item flex-1 [--bubble-offset:60px] [--help-min-width:155px]"
              v-bind="buyForm.formItemProps.priceInSun"
            >
              <template #label>
                <div class="flex items-center">
                  <span>{{
                    formData.resourceType === TronRes.ENERGY
                      ? $t("szWeyKlz1NAqT_YH-8waS")
                      : $t("qnOlaeViFYwRyIA2Ej")
                  }}</span>
                  <ATooltip>
                    <template #title>
                      {{
                        formData.resourceType === TronRes.ENERGY
                          ? $t("HY-VGEx-Los7y442vVf0i")
                          : $t("APIil9INMSHfs28Rgf_1a")
                      }}
                    </template>
                    <i
                      class="i-ant-design:question-circle-outlined ml1 text-1.25em color-#8098bf"
                    />
                  </ATooltip>
                </div>
              </template>
              <CustomInputNumber
                v-model:initiaValue="formData.priceInSun"
                :max="priceRange.c2cMaxSun"
                :min="priceRange.c2cMinSun"
                discountdiscount
              />
            </AFormItem>

            <!-- 租用时长 -->
            <AFormItem
              class="ant-cover__basic-form-item flex-1 [--bubble-offset:60px]"
              v-bind="buyForm.formItemProps.rentTimeSecond"
            >
              <template #label>
                <span>{{ $t("c1R3GTGbhHGUQ-MFaNQpC") }}</span>
              </template>
              <CustomCalendar
                :rentTimeSecond="formData.rentTimeSecond"
                mode="c2c"
                @onClick="chooseRentDuration"
              >
                <div
                  class="flex-between rounded-10px bg-#13192E px4 h-50px lt-mobile:h-40px"
                >
                  <div>{{ formData.rentDuration }}</div>

                  <span class="text-primary">
                    {{
                      (formData.rentTimeSecond ?? 0) >= 86400
                        ? $t("pTlUvYtDyBk3J0M6SwH8")
                        : $t("e5EcgLaO2CnOhsQrV3zNt")
                    }}
                    <i class="i-ep:arrow-down" />
                  </span>
                </div>
              </CustomCalendar>
            </AFormItem>
          </div>
        </div>

        <!-- 折扣信息 -->
        <div v-if="discountInfo.amount" class="text-16px text-primary mb-5px">
          <div class="flex-between">
            <span class="title">{{ $t("MdLWMNeCJsOrXnrrrWLgX") }}</span>
            <span>
              {{ formatAmount(formData.resourceValue, 0) }}
              {{ $t(ResourceType[formData.resourceType].label) }}
              <span>,</span>
              {{ formData.rentDuration }}
              <span v-if="formData.rentDuration === 1">
                {{
                  formData.rentTimeUnit === "h"
                    ? $t("SlBOLvhC-SbNV2HiN7_bi")
                    : $t("sgq3K4HMvzvjpCGigTbMu")
                }}
              </span>
              <span v-else>
                {{
                  formData.rentTimeUnit === "h"
                    ? $t("GRAxQVNTjLwXYfOpY6FRh")
                    : $t("8zE4c0R_g37KkcLgrn4u0")
                }}
              </span>
            </span>
          </div>
          <div class="flex-between">
            <span>{{ $t("5lDjHyMvhDv34o9QihLy") }}</span>
            <span>
              <span class="line-through">{{ formatAmount(discountInfo.needBurning, 6) }} TRX</span>
              <span
                class="text-22px ml1 text-text-green lt-mobile:text-base font-bold"
              >{{ formatAmount(discountInfo.amount, 6) }} TRX</span>
            </span>
          </div>
          <WaitTips v-model:value="formData.waitSecond" v-model:isAutoCancel="isAutoCancel" />
        </div>
      </AForm>
    </div>

    <div class="flex gap-20px">
      <AButton
        class="cancel-btn flex-1 hidden! lt-laptopl:block!"
        data-test-id="6ycc"
        @click="machine.send('CLOSE')"
      >
        {{ $t("hAns2WaHjEPbTgBxlqXn") }}
      </AButton>
      <AButton
        ref="target"
        class="comfirm-btn flex-1"
        :loading="states.requesting"
        data-test-id="c9w7"
        @click="onValidate"
      >
        {{ $t("cT9bJuYfJKoIN0Skkp84T") }}
      </AButton>

      <!--      <AButton class="comfirm-btn flex-1" data-test-id="c9w7" @click="newError">
        小张测试专用
      </AButton> -->
    </div>

    <BuyPopupGather />
  </div>
</template>

<style scoped lang="scss">
// 覆盖select
@media bp-lt-tabletl {
  :deep(.ant-select-single:not(.ant-select-customize-input)
.ant-select-selector) {
    height: 40px;
  }

  :deep(.ant-select-single .ant-select-selector .ant-select-selection-item) {
    line-height: 38px;
  }

  :deep(.ant-select-single.ant-select-show-arrow
.ant-select-selection-placeholder) {
    line-height: 36px;
  }
}

.buy-container {
  --uno: 'p-30px border-rd-30px flex flex-col w-100% lt-mobile:px-22px lt-mobile:py-30px lt-mobile:border-rd-16px';

  position: relative;
  color: var(--text-primary-color);
  border: 3px solid transparent;
}
</style>
